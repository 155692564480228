.full-height {
    
    height: calc(100vh - var(--nh));
}

.centered {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}